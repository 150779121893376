<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-md-flex flex-md-row flex-sm-column justify-content-between align-items-center mt-5 mb-3">
                            <span>
                                <h3>Liste des dates de fermeture</h3>
                                <label class="text-muted">Cliquer sur une fermeture pour l'annuler</label>
                            </span>
                            <block-date />
                        </div>
                        <!-- tableau -->
                        <VueCal
                        class="time"
                        :time-from="7 * 60"
                        :time-to="19 * 60"
                        :time-step="30"
                        :events="events"
                        :editable-events="{ title: true, drag: false, resize: true, delete: true, create: false }"
                        locale="fr"
                        :hideWeekdays="[1,7]"
                        :disableViews="['years','year']"
                        :on-event-click="onEventClick"
                        :special-hours="specialHours"
                        show-time-in-cells
                        />
                    </div>
                    <b-modal id="modal"  hide-footer title="Fermeture">
                        <div class="card">
                            <div class="card-body" >
                                <h3>Date début</h3>
                                <p>{{getDate(selected.start)}}</p>
                                <h3>Date Fin</h3>
                                <p>{{getDate(selected.end)}}</p>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-end my-3">
                                <button class="btn btn-link">Annuler</button>
                                <button class="btn btn-danger mx-4" @click="deleteDate()">Supprimer</button>
                            </div>
                            <div v-if="isload" class="jumping-dots-loader">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
// import 'vue-cal/dist/i18n/fr.js'
import blockDate from '../../../components/functions/block-date.vue'
// import {dateMixin} from '../mixins/date.js'
import {closingdateMixin} from '../mixins/closing_date_calendar.js'
export default {
    name:'blockdate',
    mixins:[closingdateMixin],
    components:{
        VueCal,
        blockDate
    },
    data(){
        return{
            purchases:[],
            
            selected:{
                start: new Date(),
                end: new Date()
            },
            isload:false,
            specialHours:{
            3:[
                {
                    from: 10 * 60,
                    to: 11 * 60,
                    class: 'closed',
                    label: 'fermé'
                },
                {
                    from: 15 * 60,
                    to: 16 * 60,
                    class: 'closed',
                    label: 'fermé'
                }
            ]
            }
        }
    },
    methods:{
        onEventClick(event){
            console.log({event});
            this.selected = event
            this.$bvModal.show('modal')
        },
        deleteDate(){
            axios.delete(`purchases/${this.selected.ulid}`)
            .then(resDelete => {
                console.log(resDelete)
                this.getOrders()
                this.isload=false
                this.$bvModal.hide('modal')
                alert('supprimé avec succès')
                })
            .catch(errDelete => console.log(errDelete))
        },
        getDate(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            date = date.toLocaleDateString('fr-FR', options)
            return date
        },
    },
    mounted(){
        this.getClosingDates() //dans le mixin
    }
}
</script>
<style scoped>
.time{
    min-height: 50rem;
}
::v-deep .vuecal__event{
    cursor: pointer;
}
:deep .closed {
  background:
    #fff7f0
    repeating-linear-gradient(
      -45deg,
      rgba(255, 162, 87, 0.25),
      rgba(255, 162, 87, 0.25) 5px,
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 0) 15px
    );
  color: #f6984c;
}
</style>